import toast from 'react-hot-toast';
import { axios } from './axios';
import { Consultant, ConsultantAvailability, RawConsultant } from '../../types';
import { getToken } from './helper';
import { AxiosError } from 'axios';

const ConsultantService = {
  list: async function (listParams: any) {
    return axios
      .get<Consultant[]>('/consultant', {
        headers: { authorization: getToken() },
        params: { ...listParams },
      })
      .then((response) => response.data)
      .catch((error: AxiosError | any) => {
        let errorMessage;
        if (error.response && error.response.data) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = 'Connection failed';
        }
        toast.error(errorMessage);
        throw error;
      });
  },
  update: (updateParams: any) => {
    return axios
      .put<Consultant>(`/consultant/${updateParams.id}`, updateParams, {
        headers: { authorization: getToken() },
      })
      .then((response) => response.data)
      .catch((error: AxiosError | any) => {
        let errorMessage;
        if (error.response && error.response.data) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = 'Connection failed';
        }
        toast.error(errorMessage);
        throw error;
      });
  },
  create: (createParams: RawConsultant) => {
    return axios
      .post<Consultant>(`/consultant`, createParams, {
        headers: { authorization: getToken() },
      })
      .then((response) => response.data)
      .catch((error: AxiosError | any) => {
        let errorMessage;
        if (error.response && error.response.data) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = 'Connection failed';
        }
        toast.error(errorMessage);
        throw error;
      });
  },
  delete: (consultantId: string) => {
    return axios
      .delete<unknown>(`/consultant/${consultantId}`, {
        headers: { authorization: getToken() },
      })
      .then((response) => response.data)
      .catch((error: AxiosError | any) => {
        let errorMessage;
        if (error.response && error.response.data) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = 'Connection failed';
        }
        toast.error(errorMessage);
        throw error;
      });
  },
  getAvailability: async function (listParams: { locations: string[] }) {
    return axios
      .get<ConsultantAvailability>('/consultant/availability-overview', {
        headers: { authorization: getToken() },
        params: listParams
      })
      .then((response) => response.data)
      .catch((error: AxiosError | any) => {
        let errorMessage;
        if (error.response && error.response.data) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = 'Connection failed';
        }
        toast.error(errorMessage);
        throw error;
      });
  },
};

export default ConsultantService;
