import React from "react";
import landing from "../assets/images/landingBg.webp";
import LoginForm from "../components/login-signup-pages/login-form.component";

function LoginPage() {
  return (
    <div className="flex justify-center items-center w-screen h-dynamic-screen">
      <img
        src={landing}
        className="absolute top-0 left-0 w-screen h-dynamic-screen object-cover bg-[#ffffffdb]"
        alt="PYYNE Wallpaper"
      />
      <LoginForm />
    </div>
  );
}

export default LoginPage;
