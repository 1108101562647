import React from 'react';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfirmDialogProvider } from './components/ui/confirm-dialog';
import RouteContainer from './routes/routes';
import { CurrentUserProvider } from './utils/contexts/current-user.context';

function App() {
  const queryClient = new QueryClient();
  return (
    <div className='min-h-screen bg-primary/10'>
      <Toaster />
      <QueryClientProvider client={queryClient}>
        <CurrentUserProvider>
          <ConfirmDialogProvider>
            <RouteContainer />
          </ConfirmDialogProvider>
        </CurrentUserProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
