import React from 'react';
import landing from "../assets/images/landingBg.webp";
import SignupForm from '../components/login-signup-pages/signup-form.component';

function SignupPage () {
  return (
    <div className="flex justify-center items-center w-screen h-dynamic-screen">
      <img
        src={landing}
        className="absolute top-0 left-0 w-screen h-dynamic-screen object-cover bg-[#ffffffdb]"
        alt="PYYNE Wallpaper"
      />
      <SignupForm />
    </div>
  )
}

export default SignupPage
