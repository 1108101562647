import { Bookmark, MapPin, User, X } from 'lucide-react';
import React from 'react';
import { ValueAndLabel } from '../../types';
import { useConsultantContext } from '../../utils/contexts/consultant-context';
import { cn, levelList } from '../../utils/helpers';
import { useUpdateConsultant } from '../../utils/hooks/api/consultants/useUpdateConsultant';
import { Badge } from '../ui/badge';
import { Button } from '../ui/button';

interface ConsultantDetailsProps {
  isModal?: boolean;
  handleEditMode: () => void;
  handleModalOpen: () => void;
}

export const ConsultantDetails: React.FC<ConsultantDetailsProps> = ({
  isModal,
  handleEditMode,
  handleModalOpen,
}) => {
  const { consultant, isConsultantUser } = useConsultantContext();
  const handleEdit = () => {
    handleEditMode();
    if (!isModal) {
      handleModalOpen();
    }
  };

  return (
    <div className={cn('flex flex-col gap-4 ', isModal ? '' : 'p-3')}>
      <ConsultantCardHeader />

      <div className='flex-1 w-full space-y-1'>
        <p className={cn('max-w-screen-lg text-left')}>
          <span className='font-semibold text-left '>Clients: </span>
          {consultant.clients ? consultant.clients : '-'}
        </p>
        <div
          className={cn(
            'max-w-screen-lg text-left',
            !isModal && 'line-clamp-6'
          )}
        >
          <span className='font-semibold text-left'>Skills: </span>
          <div className='flex flex-col text-xs px-16'>
            {consultant.skills && Object.keys(consultant.skills).map((category) => (
              <div key={`category-${category}`}>
                {consultant.skills[category].length ? (
                  <div className='flex gap-2 items-center p-1'>
                    <p className='font-medium'>{category}:</p>
                    <div className='flex gap-2'>
                      {consultant.skills[category].map((skill: ValueAndLabel) => (
                        <Badge key={skill.value} className='w-fit h-fit bg-primary-500/90'>
                          {skill.label}
                        </Badge>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
        <p
          className={cn(
            'max-w-screen-lg text-left',
            !isModal && 'line-clamp-6'
          )}
        >
          <span className='font-semibold text-left '>Comments: </span>
          {consultant.comments ? consultant.comments : '-'}
        </p>

        <div className='flex flex-col justify-between flex-1 h-full gap-0 md:flex-row'>
          <p
            className={cn(
              'max-w-2xl text-left',
              isModal ? 'pb-16' : 'line-clamp-6'
            )}
          >
            <span className='font-semibold text-left '>Leads: </span>
            {consultant.leads ? consultant.leads : '-'}
          </p>
          <div className='flex flex-row gap-4 pt-4 '>
            {!isConsultantUser && (
              <div
                className={cn(
                  isModal && 'flex items-center justify-center w-full'
                )}
              >
                <Button onClick={handleEdit}>Edit</Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ConsultantCardHeader = () => {
  const { consultant } = useConsultantContext();
  const { mutate: updateConsultant } = useUpdateConsultant();
  const removeFirstEngagement = () => {
    updateConsultant({ id: consultant.id, firstEngagement: false });
  };

  return (
    <>
      <div className='flex flex-col gap-1 md:gap-2'>
        <div className='flex flex-col gap-1 md:gap-4 md:items-center md:flex-row'>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-row'>
              <div
                className='px-4 py-1 text-sm min-w-[150px] flex items-center justify-center text-white rounded-full'
                style={{ backgroundColor: consultant.color.RGBCode }}
              >
                <p>{consultant.color.description}</p>
              </div>
            </div>
            {consultant.firstEngagement && (
              <div className='flex flex-row items-center min-w-0 gap-2 px-3 py-1 text-sm text-white bg-blue-400 rounded-full shadow md:hidden '>
                <p className='truncate'>First engagement</p>
                <Button
                  variant={'link'}
                  size={'icon-small'}
                  onClick={(e) => {
                    e.stopPropagation();
                    removeFirstEngagement();
                  }}
                >
                  <X className='w-5 h-5 text-white' />
                </Button>
              </div>
            )}
          </div>
          <div className='flex flex-row gap-4 text-grey'>
            <h3 className='text-xl font-semibold text-black'>
              {consultant.name}
            </h3>
            <div className='flex flex-row items-center gap-1.5'>
              <p>
                {new Date(
                  consultant.availabilityDate * 1000
                ).toLocaleDateString('sv-SE')}
              </p>
            </div>
            <div
              className={cn(
                ' flex-row text-sm items-center hidden md:flex gap-1.5'
              )}
            >
              {consultant.roles && !!consultant.roles.length && (
                <div className='flex flex-row items-center gap-1.5'>
                  <Bookmark className='w-5 h-5' />
                  {consultant.roles.map(
                    (role, index) =>
                      role +
                      (index !== consultant.roles!.length - 1 ? ' | ' : '')
                  )}
                </div>
              )}
              <div className='flex flex-row items-center gap-1.5'>
                <User className='hidden w-5 h-5 md:flex' />
                {levelList[consultant.level]?.label ?? consultant.level}
              </div>

              <div className='flex-row items-center flex gap-1.5'>
                <MapPin className='w-5 h-5' />
                {consultant.location.city}
              </div>
            </div>

            {consultant.firstEngagement && (
              <div className='flex-row items-center hidden gap-2 px-3 py-1 text-sm text-white bg-blue-400 rounded-full shadow md:flex '>
                <p>First engagement</p>
                <Button
                  variant={'link'}
                  size={'icon-small'}
                  onClick={(e) => {
                    e.stopPropagation();
                    removeFirstEngagement();
                  }}
                >
                  <X className='w-5 h-5 text-white' />
                </Button>
              </div>
            )}
          </div>
        </div>
        <div
          className={cn(
            'flex flex-row flex-wrap items-center gap-1 text-xs md:text-sm md:hidden  text-grey'
          )}
        >
          {consultant.roles && !!consultant.roles.length && (
            <div className='flex flex-row items-center gap-1.5'>
              {consultant.roles.map(
                (role, index) =>
                  role +
                  (index !== consultant.roles!.length - 1 ? ' | ' : ' | ')
              )}
            </div>
          )}
          <div className='flex flex-row items-center gap-1.5'>
            {levelList[consultant.level]?.label + ' | ' ??
              consultant.level + ' | '}
          </div>
          <div className='flex-row items-centerflex  gap-1.5'>
            {consultant.location.city}
          </div>
        </div>
      </div>
    </>
  );
};
