import React, { ReactNode, useContext, useEffect, useReducer, useState } from 'react';
import AvailabilityTable from '../components/consultants-finder-page/availability-table.component';
import { Combobox } from '../components/ui/combo-box';
import { AvailableFilters } from '../types';
import { useOffices } from '../utils/hooks/api/offices/useOffices';

interface OverviewPageProps {
  filters: AvailableFilters;
  setFilters: (value: React.SetStateAction<AvailableFilters>) => void;
}
const OverviewFilters: React.FC<OverviewPageProps> = ({
  filters,
  setFilters,
}) => {
  const { parsedOffices, isLoading: isLoadingOffices } = useOffices();

  function handleSelectOffice(option: string) {
    if (!filters.filteredOffices.some((value) => value === option)) {
      setFilters((prev) => ({
        ...prev,
        filteredOffices: [...prev.filteredOffices, option],
      }));
    } else { 
      setFilters((prev) => ({
        ...prev,
        filteredOffices: prev.filteredOffices.filter(
          (value) => value !== option
        ),
      }));
    }
  }
  function handleDeselectAllOffices() {
    setFilters({ ...filters, filteredOffices: [] });
  }
  function applySelectedOffices(offices: string[]) {
    setFilters({ ...filters, filteredOffices: offices });
  }

  return (
    <Combobox
        selectedOptions={filters.filteredOffices}
        selectName='Offices'
        className='flex-1 xs:max-w-[200px]'
        handleDeselectAll={handleDeselectAllOffices}
        options={parsedOffices ?? []}
        selectOption={handleSelectOffice}
        applyOptions={applySelectedOffices}
      />
  )
}

function OverviewPage() {
  const [filters, setFilters] = useState<AvailableFilters>({
    filteredOffices: [],
    filteredLevels: [],
    filteredStatuses: [],
    filteredRoles: [],
    availabilityDate: undefined
  });

  return (
    <div className=''>
      <h1 className='text-primary'>Overview</h1>
      <div className='md:pt-12 pb-4'>
        <OverviewFilters filters={filters} setFilters={setFilters} />
      </div>
      <AvailabilityTable filters={filters} />
    </div>
  );
}

export default OverviewPage
