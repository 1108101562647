import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { AvailableFilters, ConsultantAvailability } from '../../../../types';
import ConsultantService from '../../../services/consultant-service';

export const useAvailabilityOverview = (filters: AvailableFilters) => {
  const {
    data: availabilityOverview,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useQuery<ConsultantAvailability, AxiosError>({
    queryKey: ['consultant-availability'],
    queryFn: () => ConsultantService.getAvailability({locations: filters.filteredOffices})
  });

  return {
    availabilityOverview,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  };
};
