import React from 'react';
import landing from "../assets/images/landingBg.webp";
import ResetPasswordComponent from '../components/login-signup-pages/reset-password-component';
import UserService from '../utils/services/user-service';

function ResetPasswordPage() {
  return (
    <div className='flex justify-center items-center w-screen h-dynamic-screen'>
      <img
        src={landing}
        className="absolute top-0 left-0 w-screen h-dynamic-screen object-cover bg-[#ffffffdb]"
        alt="PYYNE Wallpaper"
      />
      <ResetPasswordComponent ResetPassword={UserService.ResetPassword}/>
    </div>
  )
}

export default ResetPasswordPage