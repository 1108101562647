import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const availableLevels = [
  { label: 'Analyst', value: 'A' },
  { label: 'Associate Consultant', value: 'AC' },
  { label: 'Consultant', value: 'C' },
  { label: 'Senior Consultant', value: 'SRC' },
  { label: 'Associate Manager', value: 'AM' },
  { label: 'Manager', value: 'M' },
  { label: 'Senior Manager', value: 'SRM' },
  { label: 'Principal Engineer', value: 'PE' },
  { label: 'Partner', value: 'P' },
];

export const levelList: { [key: string]: { label: string; value: string } } = {
  A: { label: 'Analyst', value: 'A' },
  AC: { label: 'Associate Consultant', value: 'AC' },
  C: { label: 'Consultant', value: 'C' },
  SRC: { label: 'Senior Consultant', value: 'SRC' },
  AM: { label: 'Associate Manager', value: 'AM' },
  M: { label: 'Manager', value: 'M' },
  SRM: { label: 'Senior Manager', value: 'SRM' },
  PE: { label: 'Principal Engineer', value: 'PE' },
  P: { label: 'Partner', value: 'P' },
};

export const skillSet: { [key: string]: { label: string; value: string }[] } = {
  'Programming Languages': [
    { label: 'Ada', value: 'ada' },
    { label: 'Assembly', value: 'assembly' },
    { label: 'AutoHotkey', value: 'autohotkey' },
    { label: 'Bash', value: 'bash' },
    { label: 'C', value: 'c' },
    { label: 'C#', value: 'c#' },
    { label: 'C*', value: 'c*' },
    { label: 'C++', value: 'c++' },
    { label: 'Clarity', value: 'clarity' },
    { label: 'Clojure', value: 'clojure' },
    { label: 'Concurrent C', value: 'concurrent c' },
    { label: 'Crystal', value: 'crystal' },
    { label: 'D', value: 'd' },
    { label: 'Dart', value: 'dart' },
    { label: 'Elixir', value: 'elixir' },
    { label: 'Elm', value: 'elm' },
    { label: 'Embedded C', value: 'embedded c' },
    { label: 'Erlang', value: 'erlang' },
    { label: 'F#', value: 'f#' },
    { label: 'Fortran', value: 'fortran' },
    { label: 'GDScript', value: 'gdscript' },
    { label: 'Go', value: 'go' },
    { label: 'Golang', value: 'golang' },
    { label: 'Groovy', value: 'groovy' },
    { label: 'Haskell', value: 'haskell' },
    { label: 'Haxe', value: 'haxe' },
    { label: 'Java', value: 'java' },
    { label: 'JavaScript', value: 'javascript' },
    { label: 'Julia', value: 'julia' },
    { label: 'Kotlin', value: 'kotlin' },
    { label: 'Lisp', value: 'lisp' },
    { label: 'Lua', value: 'lua' },
    { label: 'MATLAB', value: 'matlab' },
    { label: 'Maple', value: 'maple' },
    { label: 'Mercury', value: 'mercury' },
    { label: 'Michelson', value: 'michelson' },
    { label: 'Move', value: 'move' },
    { label: 'Nim', value: 'nim' },
    { label: '.NET', value: 'dotNet' },
    { label: 'OCaml', value: 'ocaml' },
    { label: 'Objective-C', value: 'objective-c' },
    { label: 'Octave', value: 'octave' },
    { label: 'PHP', value: 'php' },
    { label: 'Perl', value: 'perl' },
    { label: 'PowerShell', value: 'powershell' },
    { label: 'Prolog', value: 'prolog' },
    { label: 'Python', value: 'python' },
    { label: 'R', value: 'r' },
    { label: 'Racket', value: 'racket' },
    { label: 'Ruby', value: 'ruby' },
    { label: 'Rust', value: 'rust' },
    { label: 'SAS', value: 'sas' },
    { label: 'Scala', value: 'scala' },
    { label: 'Scheme', value: 'scheme' },
    { label: 'Solidity', value: 'solidity' },
    { label: 'Swift', value: 'swift' },
    { label: 'Tcl', value: 'tcl' },
    { label: 'Typescript', value: 'typescript' },
    { label: 'VB', value: 'vb' },
    { label: 'VHDL', value: 'vhdl' },
    { label: 'Vala', value: 'vala' },
    { label: 'Verilog', value: 'verilog' },
    { label: 'Visual Basic', value: 'visual basic' },
    { label: 'Vyper', value: 'vyper' },
    { label: 'Zig', value: 'zig'}
  ],
  Frontend: [
    {label: 'Angular', value: 'angular' },
    {label: 'Ant Design', value: 'ant design' },
    {label: 'Bootstrap', value: 'bootstrap' },
    {label: 'Chakra UI', value: 'chakra ui' },
    {label: 'CSS', value: 'css' },
    {label: 'Emotion', value: 'emotion' },
    {label: 'HTML', value: 'html' },
    {label: 'Jetpack Compose', value: 'jetpackCompose' },
    {label: 'jQuery', value: 'jquery' },
    {label: 'Less', value: 'less' },
    {label: 'Material-UI', value: 'material-ui' },
    {label: 'Materialize', value: 'materialize' },
    {label: 'MobX', value: 'mobx' },
    {label: 'Next.js', value: 'next.js' },
    {label: 'Nuxt.js', value: 'nuxt.js' },
    {label: 'Parcel', value: 'parcel' },
    {label: 'Preact', value: 'preact' },
    {label: 'Radix UI', value: 'radix ui' },
    {label: 'React', value: 'react' },
    {label: 'Recoil', value: 'recoil' },
    {label: 'Redux', value: 'redux' },
    {label: 'Remix', value: 'remix' },
    {label: 'Rollup', value: 'rollup' },
    {label: 'SCSS', value: 'scss' },
    {label: 'Sass', value: 'sass' },
    {label: 'ShadCN', value: 'shadcn' },
    {label: 'Solid.js', value: 'solid.js' },
    {label: 'Styled Components', value: 'styled components' },
    {label: 'Svelte', value: 'svelte' },
    {label: 'TailwindCSS', value: 'tailwindcss' },
    {label: 'Vite', value: 'vite' },
    {label: 'Vue.js', value: 'vue.js' },
    {label: 'Webpack', value: 'webpack' },
    {label: 'Zustand', value: 'zustand' }
  ],
  Backend: [
    { label: 'ASP.NET', value: 'asp.net' },
    { label: 'Actix', value: 'actix' },
    { label: 'Apollo', value: 'apollo' },
    { label: 'Axum', value: 'axum' },
    { label: 'Blazor', value: 'blazor' },
    { label: 'Buffalo', value: 'buffalo' },
    { label: 'CodeIgniter', value: 'codeigniter' },
    { label: 'Crow', value: 'crow' },
    { label: 'Dancer', value: 'dancer' },
    { label: 'Django', value: 'django' },
    { label: 'Echo', value: 'echo' },
    { label: 'Express', value: 'express' },
    { label: 'FastAPI', value: 'fastapi' },
    { label: 'Fastify', value: 'fastify' },
    { label: 'Fiber', value: 'fiber' },
    { label: 'Flask', value: 'flask' },
    { label: 'Gin', value: 'gin' },
    { label: 'GraphQL', value: 'graphql' },
    { label: 'Hapi', value: 'hapi' },
    { label: 'Koa', value: 'koa' },
    { label: 'Laravel', value: 'laravel' },
    { label: 'Micronaut', value: 'micronaut' },
    { label: 'Mojolicious', value: 'mojolicious' },
    { label: 'NestJS', value: 'nestjs' },
    { label: 'Node.js', value: 'node.js' },
    { label: 'POCO', value: 'poco' },
    { label: 'Phalcon', value: 'phalcon' },
    { label: 'Phoenix', value: 'phoenix' },
    { label: 'Quarkus', value: 'quarkus' },
    { label: 'Relay', value: 'relay' },
    { label: 'Rocket', value: 'rocket' },
    { label: 'Ruby on Rails', value: 'ruby on rails' },
    { label: 'Sinatra', value: 'sinatra' },
    { label: 'Spring Boot', value: 'spring boot' },
    { label: 'Symfony', value: 'symfony' },
    { label: 'Vert.x', value: 'vert.x' },
    { label: 'Yii', value: 'yii' },
    { label: 'gRPC', value: 'grpc' }
  ],
  Database: [
    { label: 'Amazon Neptune', value: 'amazon neptune' },
    { label: 'Amazon Athena', value: 'amazon athena' },
    { label: 'Apache Kafka', value: 'apache kafka' },
    { label: 'Cassandra', value: 'cassandra' },
    { label: 'CouchDB', value: 'couchdb' },
    { label: 'Amazon DynamoDB', value: 'amazon dynamodb' },
    { label: 'Eloquent', value: 'eloquent' },
    { label: 'Elastic Search', value: 'elastic search' },
    { label: 'Google BigQuery', value: 'google bigquery' },
    { label: 'Google Bigtable', value: 'google bigtable' },
    { label: 'HBase', value: 'hbase' },
    { label: 'Hibernate', value: 'hibernate' },
    { label: 'IBM Db2', value: 'ibm db2' },
    { label: 'MariaDB', value: 'mariadb' },
    { label: 'Memcached', value: 'memcached' },
    { label: 'Microsoft SQL Server', value: 'microsoft sql server' },
    { label: 'MongoDB', value: 'mongodb' },
    { label: 'Mongoose', value: 'mongoose' },
    { label: 'MySQL', value: 'mysql' },
    { label: 'Neo4j', value: 'neo4j' },
    { label: 'Oracle DB', value: 'oracle db' },
    { label: 'OrientDB', value: 'orientdb' },
    { label: 'PostgreSQL', value: 'postgresql' },
    { label: 'Prisma', value: 'prisma' },
    { label: 'Redis', value: 'redis' },
    { label: 'RethinkDB', value: 'rethinkdb' },
    { label: 'SQLAlchemy', value: 'sqlalchemy' },
    { label: 'SQLite', value: 'sqlite' },
    { label: 'Sequelize', value: 'sequelize' },
    { label: 'TypeORM', value: 'typeorm' }
  ],
  'Cloud Architecture': [
    { label: 'Akamai', value: 'akamai' },
    { label: 'Amazon Web Services', value: 'amazon web services' },
    { label: 'AWS CloudFront', value: 'aws cloudfront' },
    { label: 'AWS EC2', value: 'aws ec2' },
    { label: 'AWS ECS', value: 'aws ecs' },
    { label: 'AWS Elastic Beanstalk', value: 'aws elastic beanstalk' },
    { label: 'AWS EventBridge', value: 'aws eventbridge' },
    { label: 'AWS Glue', value: 'aws glue' },
    { label: 'AWS Lambda', value: 'aws lambda' },
    { label: 'AWS SQS', value: 'aws sqs' },
    { label: 'AWS S3', value: 'aws s3' },
    { label: 'Azure Functions', value: 'azure functions' },
    { label: 'Cloudflare', value: 'cloudflare' },
    { label: 'Fastly', value: 'fastly' },
    { label: 'Google App Engine', value: 'google app engine' },
    { label: 'Google Cloud Functions', value: 'google cloud functions' },
    { label: 'Google Cloud Platform', value: 'google cloud platform' },
    { label: 'Google Cloud Run', value: 'google cloud run' },
    { label: 'Google Compute Engine', value: 'google compute engine' },
    { label: 'Heroku', value: 'heroku' },
    { label: 'IBM Cloud', value: 'ibm cloud' },
    { label: 'Kubernetes', value: 'kubernetes' },
    { label: 'Microsoft Azure', value: 'microsoft azure' },
    { label: 'Microsoft Azure VMs', value: 'microsoft azure vms' },
    { label: 'Netlify', value: 'netlify' },
    { label: 'OpenShift', value: 'openshift' },
    { label: 'Oracle Cloud', value: 'oracle cloud' },
    { label: 'RabbitMQ', value: 'rabbitmq' },
    { label: 'Vercel', value: 'vercel' }
  ],
  'Devops & CI/CD': [
    { label: 'Ansible', value: 'ansible' },
    { label: 'AWS CloudFormation', value: 'aws cloudformation' },
    { label: 'Bamboo', value: 'bamboo' },
    { label: 'Chef', value: 'chef' },
    { label: 'CircleCI', value: 'circleci' },
    { label: 'CRI-O', value: 'cri-o' },
    { label: 'Datadog', value: 'datadog' },
    { label: 'Docker', value: 'docker' },
    { label: 'Docker Compose', value: 'docker compose' },
    { label: 'Docker Swarm', value: 'docker swarm' },
    { label: 'Fluentd', value: 'fluentd' },
    { label: 'Git', value: 'git' },
    { label: 'GitHub Actions', value: 'github actions' },
    { label: 'GitLab CI', value: 'gitlab ci' },
    { label: 'Grafana', value: 'grafana' },
    { label: 'Helm', value: 'helm' },
    { label: 'Jenkins', value: 'jenkins' },
    { label: 'Loki', value: 'loki' },
    { label: 'Mercurial', value: 'mercurial' },
    { label: 'Podman', value: 'podman' },
    { label: 'Prometheus', value: 'prometheus' },
    { label: 'Pulumi', value: 'pulumi' },
    { label: 'Puppet', value: 'puppet' },
    { label: 'SaltStack', value: 'saltstack' },
    { label: 'Splunk', value: 'splunk' },
    { label: 'Subversion', value: 'subversion' },
    { label: 'Terraform', value: 'terraform' },
    { label: 'Travis CI', value: 'travis ci' }
  ],
  'Mobile Development': [
    { label: 'Dagger', value: 'dagger' },
    { label: 'Expo', value: 'expo' },
    { label: 'Flutter', value: 'flutter' },
    { label: 'Hilt', value: 'hilt' },
    { label: 'Ionic', value: 'ionic' },
    { label: 'NativeScript', value: 'nativescript' },
    { label: 'React Native', value: 'react native' },
    { label: 'Xamarin', value: 'xamarin' }
  ],
  'QA & Testing': [
    { label: 'Apache JMeter', value: 'apache jmeter' },
    { label: 'Appium', value: 'appium' },
    { label: 'Cucumber', value: 'cucumber' },
    { label: 'Cypress', value: 'cypress' },
    { label: 'ESLint', value: 'eslint' },
    { label: 'Espresso', value: 'espresso' },
    { label: 'Gatling', value: 'gatling' },
    { label: 'JUnit', value: 'junit' },
    { label: 'Jasmine', value: 'jasmine' },
    { label: 'Jest', value: 'jest' },
    { label: 'k6', value: 'k6' },
    { label: 'Locust', value: 'locust' },
    { label: 'Mocha', value: 'mocha' },
    { label: 'Nose', value: 'nose' },
    { label: 'PHPUnit', value: 'phpunit' },
    { label: 'Playwright', value: 'playwright' },
    { label: 'Puppeteer', value: 'puppeteer' },
    { label: 'PyTest', value: 'pytest' },
    { label: 'RestAssured', value: 'restassured' },
    { label: 'RuboCop', value: 'rubocop' },
    { label: 'Selenium', value: 'selenium' },
    { label: 'SonarQube', value: 'sonarqube' },
    { label: 'Stylelint', value: 'stylelint' },
    { label: 'TSLint', value: 'tslint' },
    { label: 'TestCafe', value: 'testcafe' },
    { label: 'TestNG', value: 'testng' },
    { label: 'Unittest', value: 'unittest' },
    { label: 'Vitest', value: 'vitest' },
    { label: 'xUnit', value: 'xunit' }
  ],
  'Monitoring & Logging': [
    { label: 'AWS CloudWatch', value: 'aws cloudwatch' },
    { label: 'Grafana', value: 'grafana' },
    { label: 'Prometheus', value: 'prometheus' },
    { label: 'Sentry', value: 'sentry' },
  ],
  'Authentication & Security': [
    { label: 'AWS IAM', value: 'aws iam' },
    { label: 'Auth0', value: 'auth0' },
    { label: 'Burp Suite', value: 'burp suite' },
    { label: 'Firebase', value: 'firebase' },
    { label: 'JWT', value: 'jwt' },
    { label: 'Keycloak', value: 'keycloak' },
    { label: 'Metasploit', value: 'metasploit' },
    { label: 'Nikto', value: 'nikto' },
    { label: 'Nmap', value: 'nmap' },
    { label: 'OAuth2', value: 'oauth2' },
    { label: 'OWASP ZAP', value: 'owasp zap' },
    { label: 'Okta', value: 'okta' },
    { label: 'OpenID Connect', value: 'openid connect' }
  ],
  'Data Science & Machine Learning': [
    { label: 'AWS SageMaker', value: 'aws sagemaker' },
    { label: 'Apache Flink', value: 'apache flink' },
    { label: 'Apache Hadoop', value: 'apache hadoop' },
    { label: 'Apache Spark', value: 'apache spark' },
    { label: 'Azure ML', value: 'azure ml' },
    { label: 'D3.js', value: 'd3.js' },
    { label: 'Dask', value: 'dask' },
    { label: 'Databricks', value: 'databricks' },
    { label: 'Google Vertex AI', value: 'google vertex ai' },
    { label: 'Keras', value: 'keras' },
    { label: 'Kubeflow', value: 'kubeflow' },
    { label: 'Looker', value: 'looker' },
    { label: 'Matplotlib', value: 'matplotlib' },
    { label: 'NumPy', value: 'numpy' },
    { label: 'Pandas', value: 'pandas' },
    { label: 'Plotly', value: 'plotly' },
    { label: 'Power BI', value: 'power bi' },
    { label: 'Presto', value: 'presto' },
    { label: 'PyTorch', value: 'pytorch' },
    { label: 'PySpark', value: 'pyspark' },
    { label: 'Scikit-learn', value: 'scikit-learn' },
    { label: 'Seaborn', value: 'seaborn' },
    { label: 'Tableau', value: 'tableau' },
    { label: 'TensorFlow', value: 'tensorflow' }
  ],
  'Game Development': [
    { label: 'Cocos2d', value: 'cocos2d' },
    { label: 'CryEngine', value: 'cryengine' },
    { label: 'Godot', value: 'godot' },
    { label: 'Phaser', value: 'phaser' },
    { label: 'Unity', value: 'unity' },
    { label: 'Unreal Engine', value: 'unreal engine' },
    { label: 'libGDX', value: 'libgdx' }
  ],
  'Design Tools': [
    { label: 'Adobe XD', value: 'adobe xd' },
    { label: 'Figma', value: 'figma' },
    { label: 'Framer', value: 'framer' },
    { label: 'Illustrator', value: 'illustrator' },
    { label: 'InVision', value: 'invision' },
    { label: 'Photoshop', value: 'photoshop' },
    { label: 'Sketch', value: 'sketch' }
  ]
}
