import { Search } from 'lucide-react';
import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { CurrentUserContext } from '../../utils/contexts/current-user.context';
import UserService from '../../utils/services/user-service';
import InviteUserModal from '../manage-users-page/invite-user-modal.component';
import ManageUsersTable from '../manage-users-page/manage-users-table.component';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Loader } from '../ui/loader';

function ManageUsers() {
  const { getAxios } = useContext(CurrentUserContext);
  const axios = getAxios();

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);

  // Invite Modal variables
  const [inviteOpen, setInviteOpen] = useState(false);
  const handleInviteOpen = () => setInviteOpen(true);
  const handleInviteClose = () => setInviteOpen(false);

  const [inviteUserParams, setInviteUserParams] = useState({
    email: '',
    role: 'admin',
  });

  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    getUsers();
  }, [searchQuery]);

  async function getUsers() {
    setIsloading(true);
    const queryParams = {
      searchQuery: searchQuery,
    };
    const { status, users } = await UserService.list(axios, queryParams);
    if (status) {
      setFilteredUsers(users);
      setIsloading(false);
    }
  }

  const inviteUser = async () => {
    if (!inviteUserParams.email) {
      toast.error('Email is a required field');
    } else if (!inviteUserParams.role) {
      toast.error('Role is a required field');
    } else {
      const { status } = await UserService.invite(axios, inviteUserParams);
      if (status) {
        await getUsers();
        setInviteUserParams({
          email: '',
          role: 'admin',
        });
        handleInviteClose();
      }
    }
  };

  return (
    <div className='space-y-8'>
      <div className='flex flex-col-reverse items-center justify-between gap-4 md:flex-row'>
        <Input
          className='w-[312px]'
          leadingIcon={<Search className='w-4 h-4' />}
          value={searchQuery}
          placeholder={`Search for User's name`}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        <div className='page-header'>
          <Button onClick={handleInviteOpen}>Invite User</Button>
        </div>
      </div>
      <Loader loading={isLoading} />
      <ManageUsersTable filteredUsers={filteredUsers} getUsers={getUsers} />
      <InviteUserModal
        open={inviteOpen}
        handleClose={handleInviteClose}
        onSubmit={inviteUser}
        inviteParams={inviteUserParams}
        setInviteParams={setInviteUserParams}
      />
    </div>
  );
}

export default ManageUsers;
