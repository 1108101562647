import React from 'react';
import landing from "../assets/images/landingBg.webp";
import SendPasswordResetLinkComponent from '../components/login-signup-pages/send-password-reset-component';
import UserService from '../utils/services/user-service';

function SendPasswordResetLinkPage() {
  return (
    <div className='flex justify-center items-center w-screen h-dynamic-screen'>
      <img
        src={landing}
        className="absolute top-0 left-0 w-screen h-dynamic-screen object-cover bg-[#ffffffdb]"
        alt="PYYNE Wallpaper"
      />
      <SendPasswordResetLinkComponent SendPasswordReset={UserService.sendPasswordReset}/>
    </div>
  )
}

export default SendPasswordResetLinkPage