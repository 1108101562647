import React, { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import pyyneLogo from '../../assets/images/logo-high-res-tree-only.png';
import { CurrentUserContext } from '../../utils/contexts/current-user.context';
import useQuery from '../../utils/hooks/useQuery';
import UserService from '../../utils/services/user-service';
import { Button } from '../ui/button';

function SignupForm() {
  const [signupInfo, setSignupInfo] = useState({
    name: '',
    password: '',
    passwordConfirmation: '',
    token: '',
  });
  let query = useQuery();
  // const history = useHistory()

  const { getAxios } = useContext(CurrentUserContext);
  const signup = async (signupParams) => {
    if (signupInfo.password !== signupInfo.passwordConfirmation) {
      toast.error('Password must be equal to Password Confirmation');
      return false;
    }
    const { status } = await UserService.signup(getAxios(), signupParams);
    return status;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    let signupInfoWithToken = signupInfo;
    signupInfoWithToken['token'] = query.get('invite_token');
    const response = await signup(signupInfoWithToken);
    if (response) {
      // history.push('/login')
    }
    // history.push('/login')
  };

  return (
    <div className='flex relative w-full max-w-sm justify-center items-center p-8 rounded-2xl bg-[#E7FADACA]'>
      <form onSubmit={submitHandler}>
        <img src={pyyneLogo} alt='PYYNE Logo' className='mx-auto h-auto w-2/5' />
        <h2 className='text-center'>Signup</h2>
        <div className='flex m-1 p-1'>
          <input
            type='text'
            name='name'
            id='name'
            placeholder='Name'
            onChange={(e) =>
              setSignupInfo({ ...signupInfo, name: e.target.value })
            }
            value={signupInfo.name}
            className='w-full p-1 rounded-lg'
          />
        </div>
        <div className='flex m-1 p-1'>
          <input
            type='password'
            name='password'
            id='password'
            placeholder='Password'
            onChange={(e) =>
              setSignupInfo({ ...signupInfo, password: e.target.value })
            }
            value={signupInfo.password}
            className='w-full p-1 rounded-lg'
          />
        </div>
        <div className='flex m-1 p-1'>
          <input
            type='password'
            name='password-confirmation'
            id='password-confirmation'
            placeholder='Password Confirmation'
            onChange={(e) =>
              setSignupInfo({
                ...signupInfo,
                passwordConfirmation: e.target.value,
              })
            }
            value={signupInfo.passwordConfirmation}
            className='w-full p-1 rounded-lg'
          />
        </div>
        <div className='flex m-2 justify-center items-center p-1'>
          <Button type='submit'>Register</Button>
        </div>
        <Button variant={'link'} to='/login'>
          Have an account?
        </Button>
      </form>
    </div>
  );
}

export default SignupForm;
