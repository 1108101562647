import React, { useContext, useState } from 'react';
// @ts-ignore
import pyyneLogo from '../../assets/images/logo-high-res-tree-only.png';
import { CurrentUserContext } from '../../utils/contexts/current-user.context';
import { Button } from '../ui/button';

function SendPasswordResetLinkComponent({ SendPasswordReset, error }) {
  const [passwordResetInfo, setPasswordResetInfo] = useState({ email: '' });

  const { getAxios } = useContext(CurrentUserContext);

  const submitHandler = (e) => {
    e.preventDefault();
    SendPasswordReset(getAxios(), passwordResetInfo);
  };
  return (
    <div className='flex relative w-full max-w-sm justify-center items-center p-8 rounded-2xl bg-[#E7FADACA]'>
      <form onSubmit={submitHandler}>
        <img src={pyyneLogo} alt='PYYNE Logo' className='mx-auto h-auto w-2/5' />
        <h2 className='text-center'>Forgot Password</h2>
        <div className='flex m-1 p-1'>
          <input
            type='email'
            name='email'
            placeholder='Email'
            id='email'
            onChange={(e) =>
              setPasswordResetInfo({
                ...passwordResetInfo,
                email: e.target.value,
              })
            }
            value={passwordResetInfo.email}
            className='w-full p-1 rounded-lg'
          />
        </div>
        <div className='flex m-2 justify-center items-center p-1'>
          <Button type='submit'>Send Password Reset</Button>
        </div>
        <Button variant={'link'} to='/login'>
          Have an account?
        </Button>
      </form>
    </div>
  );
}

export default SendPasswordResetLinkComponent;
